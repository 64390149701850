﻿// form styles
.multiStepForm {
    text-align: center;
    position: relative;
    margin-top: 30px;

    &__title {
        font-size: 18px;
        text-transform: uppercase;
        color: #2C3E50;
        margin-bottom: 10px;
        letter-spacing: 2px;
        font-weight: bold;
    }

    &__subtitle {
        font-weight: normal;
        font-size: 13px;
        color: #666;
        margin-bottom: 20px;
    }

    &__step {
        background: white;
        border: 0 none;
        border-radius: 0px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
        padding: 20px 30px;
        box-sizing: border-box;
        width: 80%;
        margin: 0 10%;
        /*stacking fieldsets above each other*/
        position: relative;

        &:not(:first-of-type) {
            display: none;
        }
    }
    // Progressbar
    &__progressbar {
        margin-bottom: 30px;
        overflow: hidden;
        counter-reset: step;
        display: flex;
        justify-content: space-between;

        &-item {
            list-style-type: none;
            text-transform: uppercase;
            font-size: 9px;
            float: left;
            position: relative;
            letter-spacing: 1px;
            width: 25%;

            &.active:before, &.active:after {
                background: #ee0979;
                color: white;
            }

            &.active:after {
                transform: scaleX(1);
            }

            &:before {
                content: counter(step);
                counter-increment: step;
                width: 24px;
                height: 24px;
                line-height: 26px;
                display: block;
                font-size: 12px;
                color: #333;
                background: white;
                border-radius: 25px;
                margin: 0 auto 10px auto;
                transition: .2s ease;
                transition-delay: .2s;
                //transform: scale(0);
            }

            &.noDelay:before {
                transition-delay: 0s;
            }

            &.noDelay:after {
                transition-delay: .2s;
            }

            &:after {
                content: '';
                width: 100%;
                transform: scaleX(0);
                height: 2px;
                background: #ee0979;
                position: absolute;
                left: -50%;
                top: 9px;
                z-index: -1; /*put it behind the numbers*/
                transition: .2s ease;
                transform-origin: 0% 50%;
            }

            &:first-child:after {
                content: none;
            }
        }
    }
}

/*buttons
#msform .action-button {
    width: 100px;
    background: #ee0979;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #ee0979;
}

#msform .action-button-previous {
    width: 100px;
    background: #C5C5F1;
    font-weight: bold;
    color: black;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}
    */
/*headings*/